// @codekit-prepend 'jquery-3.1.1.min.js';

// $('.checkbox-wrap label').change(function(){
//   $(this).toggleClass('checked');
// });
//
//
// $('.remember label').click(function(){
//   $('.remember .remember-check').toggleClass('checked');
// });
//
//
// $('.remember .remember-check').click(function(){
  // $(this).toggleClass('checked');
// });
//
$('form').submit(function(e) {
  let ref = $(this).find('[required]');
  $(ref).each(function(){
    if ($(this).val() == '')
    {
      $('form').append('<p>Verification code required.</p>');
      $(this).focus();
      e.preventDefault();
      return false;
    }
  });
  return true;
});
